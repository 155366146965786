import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import TyheaderImg from "../assets/images/TyheaderImg.webp";

export const Tyheader = () => {
  const theme = useTheme();

  return (
    <div className="flex flex-col md:flex-row md:mt-8 justify-center ">
      <div>
        <img src={TyheaderImg} alt="TyheaderImg " />
      </div>
      <div className="mt-14 text-center">
        <Typography style={{ color: theme.palette.primary.main, fontSize: 58 }}>
          <b>Tech4Youth</b>
        </Typography>
        <Typography className="pt-5  text-center" style={{ fontSize: 28 }}>
          <b>
            Nous préparons aussi <br />
            votre avenir!
          </b>
        </Typography>
      </div>
    </div>
  );
};
