import React, { useState } from "react";
import {
  ABOUT,
  ACTIONS,
  BUSTOUR,
  CONTACT,
  DIGITALCAREER,
  TECH4DIVERSITY,
  TECH4YOUTH,
  WELCOME,
  WOMENFORWARD,
} from "../routes/frontend-routes";
import { Link } from "react-router-dom";
import logo from "../assets/images/LeTechspace-LOGO.png";
import { useTheme } from "@mui/material/styles";
import ModalHeader from "./ModalHeader";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import aboutimg1 from "../assets/images/aboutimg1.png";

export const History = () => {
  const theme = useTheme();

  return (
    <section style={{borderWidth:"1px"}} className=" bg-white mx-auto  flex flex-col items-center justify-between rounded-sm shadow-sm shadow-black   border-black ">
      <Typography
        
        style={{ color: theme.palette.primary.main , fontSize:30  }}
        className=" flex flex-col mt-3 md:mt-0 pl-3 w-full py-10 text-center"
      >
        NOTRE HISTOIRE
      </Typography>

      <div className=" flex flex-col  mt-3 md:mt-0 w-full   md:flex-row items-center bottom-80 ">
        <div className=" md:w-5/12 md:mt-24 mt-0  w-full ">
          <img src={aboutimg1} alt="aboutImg1"  style={{ width:"100%"}}/>
        </div>

        <div className=" flex flex-col mt-10 md:mt-0 w-full md:w-7/12 px-4 py-8  ">
          <Typography
            className=" py-3"
            variant="h4"
            style={{
              fontSize: 10,

              color: theme.palette.primary.main,
            }}
          >
            <b>Pourquoi le Techspace ?</b>
          </Typography>

          <Typography
            className=" py-4 w-full leading-5"
            style={{
              fontSize: 10,
              color: theme.palette.primary.main,
            }}
          >
            Durant notre parcours nous avons été confrontées aux mêmes obstacles
            :
            <b> manque de compétences digitales ou background informatique ,</b>{" "}
            sentiment d’être submergées et perdues face à la masse
            d'informations et formations existantes pour démarrer une transition
            vers une carrière dans le digital.
          </Typography>

          <Typography
            className=" py-4 "
            variant="h4"
            style={{ fontSize: 10, color: theme.palette.primary.main }}
          >
            <b>Cela a marqué le départ de notre histoire !</b>
          </Typography>

          <Typography
            className=" py-4  w-full leading-5 "
            style={{
              fontSize: 10,
              color: theme.palette.primary.main,
            }}
          >
            Après avoir réussi, non sans difficultés, cette transition
            professionnelle, avoir acquis les compétences nécessaires et
            construit un réseau de collègues et partenaires, nous avons eu{" "}
            <b>
              envie de créer une structure proposant de répondre concrètement
              aux questions
            </b>{" "}
            suivantes : Comment acquérir des compétences numériques ? Quelles
            sont les bases à avoir ? Vers quel domaine du digital s'orienter ?
            Comment se former en fonction de mes réalités ? Quels sont les
            métiers en demande ? Quels sont les salaires ? Quelle formation est
            la plus adaptée à mon parcours, ma réalité ? Employée ou freelance ?
          </Typography>

          <Typography
            className=" py-4 w-full leading-5 "
            variant="h4"
            style={{ fontSize: 10, color: theme.palette.primary.main }}
          >
            <b>
              Que ce soit pour sa vie quotidienne ou sa carrière, une transition
              vers le digital devient urgente et ne s'improvise pas !
            </b>
          </Typography>
        </div>
      </div>
    </section>
  );
};
