import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ModalHeader({
  links,
  dropLinks,
  open,
  setOpen,
  selected,
  setSelected,
  selectedDrop,
  setSelectedDrop,
}) {
  const theme = useTheme();
  const [openDrop, setOpenDrop] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullScreen
        fullWidth
        sx={{
          height: "75%",
          ".MuiPaper-root": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          marginTop: 10,
        }}
      >
        <ul className="flex flex-col justify-center items-center p-4">
          {links.map((link, index) => (
            <li key={index} className="mb-8">
              {index === 1 &&
                (!openDrop ? (
                  <KeyboardArrowDownIcon
                    className="text-black"
                    onClick={() => setOpenDrop(!openDrop)}
                    style={{ fontSize: 30, marginLeft: -28 }}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    className="text-black"
                    onClick={() => setOpenDrop(!openDrop)}
                    style={{ fontSize: 30, marginLeft: -28 }}
                  />
                ))}
              <Link
                to={link.url}
                onClick={() => setSelected(index)}
                style={{
                  color:
                    selected === index ? theme.palette.text.lightBlue : "white",
                  fontSize: 11,
                }}
                className=" px-3 text-xs font-bold link"
              >
                {link.name}
              </Link>
              {openDrop && index === 1 && (
                <ul className="flex flex-col py-2 items-center justify-center">
                  {dropLinks.map((link, index2) => (
                    <>
                      {index === 1 && (
                        <li
                          key={index2}
                          className={`${index2 === 0 ? "mt-4" : "mt-8"}`}
                        >
                          <Link
                            to={link.url}
                            style={{ fontSize: 11 }}
                            className="text-white font-light link"
                          >
                            {link.name}
                          </Link>
                        </li>
                      )}
                    </>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </Dialog>
    </React.Fragment>
  );
}
