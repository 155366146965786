import React from "react";
import { SessionLayout } from "./SessionLayout";
import { Sessiongrid } from "../components/Sessiongrid";
import arrowImg from "../assets/images/arrowImg.png";
import clockImg from "../assets/images/clockImg.png";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";

export const MySessions = () => {
  const theme = useTheme();

  const sessionGrid = [
    {
      id: 0,
      number: "05 ",
      monYear: "June 2024",
      image: clockImg,
      time: "09:00 am",
      noSessions: "Session-1",
      mentor: "Mentor Arthur Ngenzi",
      buttons: "Join",
    },
    {
      id: 1,
      number: "06 ",
      monYear: "June 2024",
      image: clockImg,
      time: "09:00 am",
      noSessions: "Session-2",
      mentor: "Mentor Arthur Ngenzi",
      buttons: "Join",
    },

    {
      id: 2,
      number: "07 ",
      monYear: "June 2024",
      image: clockImg,
      time: "09:00 am",
      noSessions: "Session-3",
      mentor: "Mentor Arthur Ngenzi",
      buttons: "Join",
    },

    {
      id: 3,
      number: "08 ",
      monYear: "June 2024",
      image: clockImg,
      time: "09:00 am",
      noSessions: "Session-4",
      mentor: "Mentor Arthur Ngenzi",
      buttons: "Join",
    },
    {
      id: 4,
      number: "09 ",
      monYear: "June 2024",
      image: clockImg,
      time: "09:00 am",
      noSessions: "Session-5",
      mentor: "Mentor Arthur Ngenzi",
      buttons: "Join",
    },
    {
      id: 5,
      number: "10 ",
      monYear: "June 2024",
      image: clockImg,
      time: "09:00 am",
      noSessions: "Session-6",
      mentor: "Mentor Arthur Ngenzi",
      buttons: "Join",
    },
  ];

  return (
    <SessionLayout>
      <div>
        <Typography
          className="ml-8 mt-8"
          style={{ color: theme.palette.primary.main, fontSize: 30 }}
        >
          <b>Mes séances</b>
        </Typography>

        <div className="flex flex-col xl:flex-row justify-center items-center">
          <div className="mx-0 my-10 ">
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 7 }}
            >
              {sessionGrid.map((session, index) => (
                <Grid item xs={12} md={6} lg={4} xlg={4} xl={3} xxl={2}>
                  {Boolean(Object.keys(session).length) && (
                    <Sessiongrid item={session} />
                  )}
                </Grid>
              ))}
            </Grid>
          </div>

          <img
            className=" h-20 w-20 ml-0 md:ml-10 "
            src={arrowImg}
            alt="arrowImg"
          />
        </div>
      </div>
    </SessionLayout>
  );
};
