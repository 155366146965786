import React, { useState } from "react";
import {
  ABOUT,
  ACTIONS,
  BUSTOUR,
  CONTACT,
  DIGITALCAREER,
  TECH4DIVERSITY,
  TECH4YOUTH,
  WELCOME,
  WOMENFORWARD,
} from "../routes/frontend-routes";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const Equipe = () => {
  const theme = useTheme();

  return (
    <div className="  container">
      <Typography
        variant="h4"
        style={{ color: theme.palette.primary.main }}
        className="   flex flex-col  md:mt-0 text-center  py-10  "
      >
        NOTRE EQUIPE
      </Typography>

      <Typography
        style={{ color: theme.typography, fontSize: 12 }}
        className=" text-black flex flex-col px-2 pb-5 w-full leading-6"
      >
        Explorez maintenant le reste de notre équipe, ces talents dynamiques et
        dévoués qui travaillent ensemble pour concrétiser notre mission
        d'inclusion numérique et d'innovation technologique.
      </Typography>
    </div>
  );
};
