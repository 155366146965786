import React, { useState } from "react";
import { AuthLayout } from "../AuthLayout";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TechspaceTextField from "../../components/TechspaceTextField";
import TechspaceCheckbox from "../../components/TechspaceCheckbox";
import TechspaceButton from "../../components/TechspaceButton";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginUser } from "./authSlice";
import { MYSESSIONS } from "../../routes/frontend-routes";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // TODO: Implement remember me
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = () => {
    if(!email)
      return toast.error('Email must be provided');

    if(!password)
      return toast.error('Password must be provided');

    // TODO: Save jwt token
    dispatch(loginUser({
      data: { email, password },
      onSuccess: () => navigate(MYSESSIONS)
    }))
  }

  return (
    <AuthLayout>
      <div className="flex justify-center">
        <div className="bg-white auth-container p-16 mt-16 w-3/4 sm:w-1/2 lg:w-1/3">
          <Typography variant="h3" className="font-bold mb-16" color='#4D115C'>
            Se connecter
          </Typography>

          <Typography variant="h6" className="font-bold mb-1" color='secondary.main'>
            Adresse e-mail
          </Typography>
          <TechspaceTextField
            placeholder='Email'
            className="w-full mb-6"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />

          <Typography variant="h6" className="font-bold mb-1" color='secondary.main'>
            Mot de passe
          </Typography>
          <TechspaceTextField
            className="w-full mb-6"
            placeholder='Password'
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />

          <div className="flex flex-col md:flex-row justify-between md:items-center mb-20">
            <div className="flex items-center">
              <TechspaceCheckbox
                size="large"
                color="secondary"
                checked={rememberMe}
                onChange={e => setRememberMe(e.target.checked)}
              />
              <Typography color='secondary.main'>
                Souviens-toi de moi
              </Typography>
            </div>

            <Link to="#">
              <Typography color='secondary.main' className="font-bold mt-3 md:mt-0">
                Mot de passe oublié
              </Typography>
            </Link>
          </div>

          <TechspaceButton
            variant="contained"
            className="w-full"
            onClick={onSubmit}
          >
            <Typography variant="h6" className="font-bold normal-case">
              Se connecter
            </Typography>
          </TechspaceButton>
        </div>
      </div>
    </AuthLayout>
  );
};
