import { ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import { Provider } from "react-redux";
import { store } from "./store";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0C3C60",
    },
    secondary: {
      main: '#7F52FF'
    },
    text: {
      lightBlue: "#8CBCDB",
      aboutBlue: "#3179AF",
      skyblue: "#D8E8F2",
      caption: "#A7A7A7",
    },

    footer: {
      main: "#072136",
    },
  },
  typography: {
    fontFamily: "Futura Book, sans-serif",
    fontSize: 9,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1281,
      xlg: 1950,
      xxl: 2560,
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
        <ToastContainer position="bottom-center"/>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
