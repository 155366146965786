import React from "react";
import { Header } from "../components/Header";

export const AuthLayout = ({ children }) => {
  return (
    <div className="flex flex-col">
      <Header />
      <main className="loginImg">{children}</main>
    </div>
  );
};
