import React, { useState } from "react";
import {
  ABOUT,
  ACTIONS,
  BUSTOUR,
  CONTACT,
  DIGITALCAREER,
  TECH4DIVERSITY,
  TECH4YOUTH,
  WELCOME,
  WOMENFORWARD,
} from "../routes/frontend-routes";
import { Link } from "react-router-dom";
import logo from "../assets/images/LeTechspace-LOGO.png";
import { useTheme } from "@mui/material/styles";
import ModalHeader from "./ModalHeader";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";

export const AboutHeader = () => {
  const theme = useTheme();

  return (
    <div>
      <section className="  bg-white flex flex-col-reverse items-center mx-5 justify-between md:flex-row px-0 pt-16 pb-20">
        <Typography
          
          style={{ color: theme.palette.text.aboutBlue, fontSize: 30 }}
          className=" flex flex-col mt-4  md:mt-0  md:w-4/6 leading-13 w-full"
        >
          À PROPOS DE TECHSPACE
        </Typography>

        <Typography
          style={{ color: theme.typography, fontSize: 12 }}
          className=" text-black flex flex-col w-full leading-6 "
        >
          Bienvenue dans notre espace dédié à l'âme de Techspace, là où se tisse
          la trame de notre engagement et de notre passion pour l'inclusion
          numérique. Dans cette section, plongez au cœur de notre univers et
          découvrez ce qui fait battre le cœur de notre communauté. Explorez nos
          valeurs, notre vision et les acteurs clés qui façonnent notre parcours
          vers un avenir technologique plus inclusif.
        </Typography>
      </section>
    </div>
  );
};
