import React, { useEffect, useState } from "react";
import { AuthLayout } from "../AuthLayout";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TechspaceTextField from "../../components/TechspaceTextField";
import TechspaceCheckbox from "../../components/TechspaceCheckbox";
import TechspaceButton from "../../components/TechspaceButton";
import { useDispatch, useSelector } from "react-redux";
import { registerUserDetails, resetData, setAbout, setEmail, setName } from "./authSlice";
import { toast } from "react-toastify";
import validator from "validator";
import { REGISTRATION_VERIFY_OTP } from "../../routes/frontend-routes";

export const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { name, email, about } = useSelector(state => state.auth);

  const handleNameChange = (event) => {
    dispatch(setName(event.target.value));
  }

  const handleEmailChange = (event) => {
    dispatch(setEmail(event.target.value));
  }

  const handleAboutChange = (event) => {
    dispatch(setAbout(event.target.value));
  }

  const onSubmit = () => {
    if(!name || !email || !about)
      return toast.error('Some fields are missing');

    if(!validator.isEmail(email))
      return toast.error('Email format is invalid');

    localStorage.setItem('registrationData',  JSON.stringify({ name, email, about }));
    
    dispatch(registerUserDetails({
      data: {
        name,
        email,
        about
      },
      onSuccess: () => navigate(REGISTRATION_VERIFY_OTP)
    }));
  }

  useEffect(() => {
    if(localStorage.registrationData) {
      let data = JSON.parse(localStorage.registrationData);
      dispatch(setName(data.name));
      dispatch(setEmail(data.email));
      dispatch(setAbout(data.about));
    }

    return () => {
      dispatch(resetData());
    }
  }, [])

  return (
    <AuthLayout>
      <div className="flex justify-center">
        <div className="bg-white auth-container p-16 mt-16 w-3/4 sm:w-1/2 lg:w-1/3">
          <Typography variant="h3" className="font-bold mb-8" color='#4D115C'>
            S'inscrire
          </Typography>

          <Typography variant="h6" className="font-bold mb-1" color='secondary.main'>
            Nom
          </Typography>
          <TechspaceTextField
            className="w-full mb-6"
            placeholder='Name'
            value={name}
            onChange={handleNameChange}
          />

          <Typography variant="h6" className="font-bold mb-1" color='secondary.main'>
            Adresse e-mail
          </Typography>
          <TechspaceTextField
            className="w-full mb-6"
            placeholder='Email'
            type="email"
            value={email}
            onChange={handleEmailChange}
          />

          <Typography variant="h6" className="font-bold" color='secondary.main'>
            Lieu d'intérêt
          </Typography>
          <Typography variant="caption" color='text.caption'>
            Décrivez le domaine dans lequel vous avez besoin de mentorat.
          </Typography>
          <TechspaceTextField
            multiline
            minRows={6}
            maxRows={10}
            placeholder='About'
            className="w-full mb-6"
            value={about}
            onChange={handleAboutChange}
          />

          <TechspaceButton
            variant="contained"
            className="w-full"
            onClick={onSubmit}
          >
            <Typography variant="h6" className="font-bold normal-case">
              S'inscrire
            </Typography>
          </TechspaceButton>
        </div>
      </div>
    </AuthLayout>
  );
};
