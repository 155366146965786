import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const Tycenter = () => {
  const theme = useTheme();

  return (
    <div className="flex flex-col w-full justify-between  md:flex-row my-20">
      <Typography
        className=" leading-10 text-center"
        style={{ color: theme.palette.primary.main, fontSize: 25 }}
      >
        <b>
          En Partenariat avec des écoles <br />
          nous offrons un programme <br />
          d'initiation au code.
        </b>
      </Typography>
      <Typography
        className=" leading-9 mx-10 mt-10 md:mt-0"
        style={{ fontSize: 12 }}
      >
        <b>Tech4Youth</b> , un programme de formation au code pour les jeunes
        <br /> adolescents. Notre mission : les initier aux outils numériques et
        <br />
        développer leurs compétences en informatique pour leur avenir
        <br />
        professionnel de manière fun. Avec <b>Tech4Youth</b>, ils acquièrent des
        <br />
        premières bases solides en informatique, ainsi qu'une
        <br /> compréhension de la programmation et de la robotique. Nos
        <br /> formations, animées par des experts, s'adaptent à tous les
        <br /> niveaux.
      </Typography>
    </div>
  );
};
