import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import TyfooterImg1 from "../assets/images/TyfooterImg1.webp";
import TyfooterImg2 from "../assets/images/TyfooterImg2.webp";
import TyfooterImg3 from "../assets/images/Tyfooterimg3.png";

export const TyFooter = () => {
  const theme = useTheme();

  return (
    <div>
      <Typography
        className=" leading-10 text-center"
        style={{ color: theme.palette.primary.main, fontSize: 28 }}
      >
        <b>NOS PROGRAMMES EN COURS</b>
      </Typography>
      <div className="flex flex-col md:flex-row justify-center">
        <img src={TyfooterImg1} alt="TyfooterImg1" />

        <img src={TyfooterImg2} alt="TyfooterImg2" />
      </div>

      <Typography className="  leading-9 " style={{ fontSize: 12 }}>
        Il est essentiel de guider nos enfants dans leur utilisation des
        appareils numériques alors qu'ils grandissent. Notre responsabilité
        consiste à les encourager à devenir des créateurs actifs plutôt que de
        simples consommateurs passifs de ces technologies. L'apprentissage du
        codage permet aux enfants de développer une pensée analytique, des
        compétences en résolution de problèmes, ainsi que d'accroître leur
        créativité, leur concentration et leur confiance en eux-mêmes. En leur
        donnant les outils nécessaires pour comprendre et créer avec les
        technologies, nous leur offrons des opportunités infinies pour
        s'épanouir dans un monde numérique en constante évolution.
      </Typography>

      <Typography
        className=" leading-10 text-center py-10"
        style={{ color: theme.palette.primary.main, fontSize: 28 }}
      >
        <b>TECH4YOUTH et l'athénée Robert Catteau</b>
      </Typography>
      <Typography
        className=" flex justify-center leading-9 mx-10 mt-10 md:mt-0"
        style={{ fontSize: 12 }}
      >
        Depuis 2022, nous collaborons avec l'athénée robert Catteau où des
        <br /> ateliers numériques sont organisés chaque semaine
      </Typography>
      <div className="flex justify-center   mb-28">
        <img
          src={TyfooterImg3}
          alt="TyfooterImg3  "
          className="image-with-shadow "
        />
      </div>
    </div>
  );
};
