import { Button } from "@mui/material";
import { Link, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      className=" flex flex-col items-center justify-center content-center mt-32"
    >
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <Link to={"/"}>
        <Button variant="contained" className="mt-10 rounded-lg p-3">
          Go Back Home Page
        </Button>
      </Link>
    </div>
  );
}
