import React, { useState } from "react";
import {
  ABOUT,
  ACTIONS,
  BUSTOUR,
  CONTACT,
  DIGITALCAREER,
  TECH4DIVERSITY,
  TECH4YOUTH,
  WELCOME,
  WOMENFORWARD,
} from "../routes/frontend-routes";
import { Link } from "react-router-dom";
import logo from "../assets/images/LeTechspace-LOGO.png";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const EquipePerson = ({ item }) => {
  const theme = useTheme();

  return (
    <div
      className=" shadow-lg equipe-Img shadow-black mx-auto rounded-xl flex flex-col md:flex-row justify-center items-center md:items-start   "
      style={{ background: theme.palette.primary.main }}
    >
      <div style={{ width: "30%" }}>
        <img
          src={item.image}
          alt="aboutImg1"
          className="md:w-96 w-60 h-60 md:h-96  md:rounded-s-lg "
        />
      </div>
      <div className=" md-w-70 pb-2 px-6 self-start">
        <Typography className=" text-white pt-5    " style={{ fontSize: 10 }}>
          {item.title}
        </Typography>
        <Typography
          className=" w-full leading-6  pt-3   "
          style={{ color: theme.palette.text.lightBlue, fontSize: 9 }}
        >
          {item.heading}
        </Typography>
        <Typography
          className="  font-futura-light w-full pt-3 leading-5  "
          style={{ color: theme.palette.text.skyblue }}
        >
          {item.description}
        </Typography>
      </div>
    </div>
  );
};
