import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { toast } from 'react-toastify';
import { updateLoadingToast } from '../../utils/helpers';

export const registerUserDetails = createAsyncThunk(
  'auth/registerUserDetails',
  async({ data, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading('Submitting...', { toastId: 'register-user-details' });
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/register-user-details`, data);
      onSuccess?.();
      return response.data;

    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
)

export const resendOtpCode = createAsyncThunk(
  'auth/resendOtpCode',
  async(data, { rejectWithValue }) => {
    try {
      toast.loading('Resending...', { toastId: 'resend-otp-code' });
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resend-otp-code`, { params: data });
      return response.data;

    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
)

export const verifyOtpCode = createAsyncThunk(
  'auth/verifyOtpCode',
  async({ data, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading('Verifying...', { toastId: 'verify-otp-code' });
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/api/verify-otp-code`, { params: data })
      onSuccess?.();
      return response.data;

    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
)

export const setUserPassword = createAsyncThunk(
  'auth/setUserPassword',
  async({ data, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading('Submitting...', { toastId: 'set-user-password' });
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/set-user-password`, data);
      onSuccess?.();
      return response.data;

    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
)

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async({ data, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading('Logging in...', { toastId: 'login-user' });
      let response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, data);
      onSuccess?.();
      return response.data;

    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    name: '',
    email: '',
    about: '',
    otpCode: '',
    password: '',
  },
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setAbout: (state, action) => {
      state.about = action.payload;
    },
    setOtpCode: (state, action) => {
      state.otpCode = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    resetData: (state, action) => {
      state.name = '';
      state.email = '';
      state.about = '';
      state.otpCode = '';
      state.password = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(registerUserDetails.fulfilled, (state, action) => {
      toast.dismiss('register-user-details');
    });
    builder.addCase(registerUserDetails.rejected, (state, action) => {
      updateLoadingToast(action.payload?.message ?? 'Error registering user', 'error', 'register-user-details');
    });

    builder.addCase(resendOtpCode.fulfilled, (state, action) => {
      updateLoadingToast(action.payload?.message ?? 'Otp code resent successfully', 'success', 'resend-otp-code');
    });
    builder.addCase(resendOtpCode.rejected, (state, action) => {
      updateLoadingToast(action.payload?.message ?? 'Error resending otp code', 'error', 'resend-otp-code');
    });

    builder.addCase(verifyOtpCode.fulfilled, (state, action) => {
      updateLoadingToast(action.payload?.message ?? 'Otp code verified successfully', 'success', 'verify-otp-code');
    });
    builder.addCase(verifyOtpCode.rejected, (state, action) => {
      updateLoadingToast(action.payload?.message ?? 'Error verifying otp code', 'error', 'verify-otp-code');
    });

    builder.addCase(setUserPassword.fulfilled, (state, action) => {
      updateLoadingToast(action.payload?.message ?? 'Password set successfully', 'success', 'set-user-password');
    });
    builder.addCase(setUserPassword.rejected, (state, action) => {
      updateLoadingToast(action.payload?.message ?? 'Error setting password', 'error', 'set-user-password');
    });

    builder.addCase(loginUser.fulfilled, (state, action) => {
      updateLoadingToast(action.payload?.message ?? 'Logged in successfully', 'success', 'login-user');
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      updateLoadingToast(action.payload?.message ?? 'Error logging in', 'error', 'login-user');
    });
  }
})

export const {
  setName,
  setEmail,
  setAbout,
  setOtpCode,
  setPassword,
  resetData,
} = authSlice.actions

export default authSlice.reducer