import React, { useEffect, useState } from "react";
import { AuthLayout } from "../AuthLayout";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TechspaceTextField from "../../components/TechspaceTextField";
import TechspaceCheckbox from "../../components/TechspaceCheckbox";
import TechspaceButton from "../../components/TechspaceButton";
import { useDispatch, useSelector } from "react-redux";
import { resetData, setAbout, setEmail, setName, setPassword, setUserPassword } from "./authSlice";
import { toast } from "react-toastify";
import validator from "validator";
import { LOGIN, REGISTRATION, REGISTRATION_VERIFY_OTP } from "../../routes/frontend-routes";

export const SetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { email, password, otpCode } = useSelector(state => state.auth);

  const [retypedPassword, setRetypedPassword] = useState();

  const handlePasswordChange = (event) => {
    dispatch(setPassword(event.target.value));
  }

  const onSubmit = () => {
    if(password !== retypedPassword)
      return toast.error("Passwords don't match");

    dispatch(setUserPassword({
      data: {
        email,
        otp: otpCode,
        password
      },
      onSuccess: () => navigate(LOGIN)
    }));
  }

  useEffect(() => {
    if(!localStorage.registrationData)
      return navigate(REGISTRATION);

    if(!otpCode)
      return navigate(REGISTRATION_VERIFY_OTP);

    let data = JSON.parse(localStorage.registrationData);
    dispatch(setName(data.name));
    dispatch(setEmail(data.email));
    dispatch(setAbout(data.about));
  }, [])

  return (
    <AuthLayout>
      <div className="flex justify-center">
        <div className="bg-white auth-container p-16 mt-16 w-3/4 sm:w-1/2 lg:w-1/3">
          <Typography variant="h3" className="font-bold mb-8" color='#4D115C'>
            Créer un mot de passe
          </Typography>

          <Typography variant="h6" className="font-bold mb-1" color='secondary.main'>
            Mot de passe
          </Typography>
          <TechspaceTextField
            className="w-full mb-6"
            placeholder='Password'
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />

          <Typography variant="h6" className="font-bold mb-1" color='secondary.main'>
            Confirmez le mot de passe
          </Typography>
          <TechspaceTextField
            className="w-full mb-6"
            placeholder='Retyped Password'
            type="password"
            value={retypedPassword}
            onChange={e => setRetypedPassword(e.target.value)}
          />

          <TechspaceButton
            variant="contained"
            className="w-full"
            onClick={onSubmit}
          >
            <Typography variant="h6" className="font-bold normal-case">
              Sauvegarder
            </Typography>
          </TechspaceButton>
        </div>
      </div>
    </AuthLayout>
  );
};
