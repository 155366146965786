import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export default styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#D7D7D7',
      borderRadius: 8,
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)'
    },
    '&:hover fieldset': {
      borderWidth: 2,
      borderColor: '#D7D7D7',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D7D7D7',
    },
  },
});