import React from "react";
import { Layout } from "./Layout";

export const Welcome = () => {
  return (
    <Layout>
      <div></div>
    </Layout>
  );
};
