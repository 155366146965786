import React, { useState } from "react";
import {
  ABOUT,
  ACTIONS,
  BUSTOUR,
  CONTACT,
  DIGITALCAREER,
  MYSESSIONS,
  TECH4DIVERSITY,
  TECH4YOUTH,
  WELCOME,
  WOMENFORWARD,
} from "../routes/frontend-routes";
import { Link } from "react-router-dom";
import logo from "../assets/images/LeTechspace-LOGO.png";
import { useTheme } from "@mui/material/styles";
import ModalHeader from "./ModalHeader";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Typography, dividerClasses } from "@mui/material";

export const Header = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [isOpenDrop, setIsOpenDrop] = useState(false);
  const [selected, setSelected] = useState(0);
  const [selectedDrop, setSelectedDrop] = useState();

  const links = [
    {
      id: 0,
      name: "Accueil",
      url: WELCOME,
    },
    {
      id: 1,
      name: "Nos actions",
      url: ACTIONS,
    },
    {
      id: 2,
      name: "Digital Career Center",
      url: DIGITALCAREER,
    },
    {
      id: 3,
      name: "Femme Forward",
      url: WOMENFORWARD,
    },
    {
      id: 4,
      name: "À propos",
      url: ABOUT,
    },
    {
      id: 5,
      name: "Contact",
      url: CONTACT,
    },

    {
      id: 6,
      name: "Mes séances",
      url: MYSESSIONS,
    },
  ];

  const dropLinks = [
    {
      id: 0,
      name: "Tech4diversity",
      url: TECH4DIVERSITY,
    },
    {
      id: 1,
      name: "Bus Tour",
      url: BUSTOUR,
    },
    {
      id: 2,
      name: "Tech4Youth",
      url: TECH4YOUTH,
    },
  ];

  return (
    <nav className="bg-white">
      <div
        style={{ maxWidth: "2100px" }}
        className=" flex mx-auto md:flex-row flex-col justify-between mt-4 items-center"
      >
        <div className="flex flex-row justify-between items-center w-full md:w-auto">
          <Link
            to={WELCOME}
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img src={logo} className="h-18 w-32 ml-5 md:ml-24" alt="Logo" />
          </Link>
          <button
            onClick={() => setOpen(!open)}
            type="button"
            className="inline-flex items-center p-2 justify-center rounded-lg md:hidden mr-5"
          >
            {!open ? (
              <MenuIcon style={{ fontSize: 36 }} />
            ) : (
              <CloseIcon style={{ fontSize: 36 }} />
            )}
          </button>
        </div>
        <div className={`hidden w-full md:block md:w-auto`}>
          <ul className="flex relative md:flex-row flex-col p-4">
            {links.map((link, index) => (
              <li
                key={index}
                onClick={() => setSelected(index)}
                onMouseOver={() => link.id === 1 && setIsOpenDrop(true)}
                onMouseOut={() => setIsOpenDrop(false)}
              >
                <Link
                  to={link.url}
                  style={{
                    color:
                      selected === index
                        ? theme.palette.text.lightBlue
                        : theme.palette.primary.main,
                    fontSize: 11,
                  }}
                  className="py-2 px-4 font-light link"
                >
                  {link.name}
                </Link>
                {isOpenDrop && index === 1 && (
                  <ul className="absolute flex flex-col py-2 bg-white shadow-sm shadow-black">
                    {dropLinks.map((link, index2) => (
                      <li key={index2} onClick={() => setSelectedDrop(index2)}>
                        <Link
                          to={link.url}
                          className="py-2 px-3 text-xs font-light link"
                          style={{
                            color:
                              selectedDrop === index2
                                ? theme.palette.text.lightBlue
                                : theme.palette.primary.main,
                          }}
                        >
                          {link.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <Button
            className=" w-28 h-8 m-2 text-white  aboutButton  "
            style={{
              borderRadius: 60,
              background: "#0C3C60",
              fontSize: 10,
              textTransform: "none",
            }}
          >
            Se connecter
          </Button>
          <Button
            className=" w-28  h-8 m-2 text-white  aboutButton  "
            style={{
              borderRadius: 60,
              background: "#0C3C60",
              fontSize: 10,
              textTransform: "none",
            }}
          >
            S'inscrire
          </Button>
        </div>
      </div>
      <ModalHeader
        links={links}
        dropLinks={dropLinks}
        open={open}
        setOpen={setOpen}
        selected={selected}
        setSelected={setSelected}
        selectedDrop={selectedDrop}
        setSelectedDrop={setSelectedDrop}
      />
    </nav>
  );
};
