import { TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'

function OTPInput({ className, onChange, onResend }) {
  const [otpCode, setOtpCode] = useState([]);

  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef()
  ]

  const handleChangeOtp = (value, index) => {
    if(`${value}`.length > 1)
      return;

    let tempOtpCode = [ ...otpCode ];
    tempOtpCode[index] = value;
    setOtpCode(tempOtpCode);

    if(value && index < 3) {
      inputRefs[index + 1].current.focus();
    }

    if(!value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  }

  useEffect(() => {
    onChange?.(otpCode.join(''));
  }, [otpCode])

  return (
    <div className={className}>
      <div className='flex items-center mb-3'>
        <input
          type='number'
          className='otp-input mr-3'
          ref={inputRefs[0]}
          value={otpCode?.[0]}
          onChange={e => handleChangeOtp(e.target.value, 0)}
        />
        <input
          type='number'
          className='otp-input mr-3'
          ref={inputRefs[1]}
          value={otpCode?.[1]}
          onChange={e => handleChangeOtp(e.target.value, 1)}
        />
        <input
          type='number'
          className='otp-input mr-3'
          ref={inputRefs[2]}
          value={otpCode?.[2]}
          onChange={e => handleChangeOtp(e.target.value, 2)}
        />
        <input
          type='number'
          className='otp-input'
          ref={inputRefs[3]}
          value={otpCode?.[3]}
          onChange={e => handleChangeOtp(e.target.value, 3)}
        />
      </div>

      <div className='flex'>
        <Typography color='secondary.main' className='font-bold cursor-pointer' onClick={onResend}>
          Renvoyer le code
        </Typography>
      </div>
    </div>
  )
}

export default OTPInput;