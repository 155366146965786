import { createBrowserRouter } from "react-router-dom";
import { Welcome } from "../pages/Welcome";
import ErrorPage from "../utils/error-page";
import {
  ABOUT,
  ACTIONS,
  TECH4YOUTH,
  WELCOME,
  MYSESSIONS,
  LOGIN,
  REGISTRATION,
  REGISTRATION_VERIFY_OTP,
  REGISTRATION_SET_PASSWORD
} from "./frontend-routes";
import { About } from "../pages/About";
import { TechYouth } from "../pages/TechYouth";
import { MySessions } from "../pages/MySessions";
import { Login } from "../pages/auth/Login";
import { Registration } from "../pages/auth/Registration";
import { OTPVerify } from "../pages/auth/OTPVerify";
import { SetPassword } from "../pages/auth/SetPassword";

const router = createBrowserRouter([
  {
    path: WELCOME,
    element: <Welcome />,
    errorElement: <ErrorPage />,
  },
  {
    path: ABOUT,
    element: <About />,
  },
  {
    path: TECH4YOUTH,
    element: <TechYouth />,
  },

  // Auth
  {
    path: LOGIN,
    element: <Login />,
  },
  {
    path: REGISTRATION,
    element: <Registration />,
  },
  {
    path: REGISTRATION_VERIFY_OTP,
    element: <OTPVerify />,
  },
  {
    path: REGISTRATION_SET_PASSWORD,
    element: <SetPassword />,
  },
  {
    path: MYSESSIONS,
    element: <MySessions />,
  },
]);

export default router;
