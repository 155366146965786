export const WELCOME = "/";
export const ACTIONS = "/nos-actions";
export const DIGITALCAREER = "/dcc";
export const WOMENFORWARD = "/femmeforward";
export const ABOUT = "/à-propos";
export const CONTACT = "/contact-6";
export const TECH4DIVERSITY = "/tech4diversity";
export const BUSTOUR = "/bus-tour";
export const TECH4YOUTH = "/tech4youth";
export const LOGIN = "/login";
export const MYSESSIONS = "/MySessions";
export const REGISTRATION = "/registration";
export const REGISTRATION_VERIFY_OTP = "/registration-verify-otp";
export const REGISTRATION_SET_PASSWORD = "/registration-set-password";