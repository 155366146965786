import React from "react";
import { Layout } from "./Layout";
import { AboutHeader } from "../components/AboutHeader";
import { History } from "../components/History";
import aboutimg1 from "../assets/images/aboutimg1.png";
import { AboutPerson } from "../components/AboutPerson";
import personImg1 from "../assets/images/personImg1.jpg";
import personImg2 from "../assets/images/personImg2.webp";
import { Equipe } from "../components/Equipe";
import { EquipePerson } from "../components/EquipePerson";
import Eimg1 from "../assets/images/Eimg1.jpg";
import Eimg2 from "../assets/images/Eimg2.jpg";
import Eimg3 from "../assets/images/Eimg3.jpg";
import Eimg4 from "../assets/images/Eimg4.png";
import Eimg5 from "../assets/images/Eimg5.jpeg";
import Eimg6 from "../assets/images/Eimg6.jpg";
import Eimg7 from "../assets/images/Eimg7.jpg";
import Grid from "@mui/material/Grid";
import { Aboutlast } from "../components/Aboutlast";
export const About = () => {
  const person1 = [
    {
      id: 0,
      title: "PAMELA GERBINO",
      heading: "Cofondatrice et Chief Operation Officer",
      description:
        "J’ai un background marketing et une expérience de longue durée dans l'office management. Je me suis ensuite orientée vers une carrière digitale et je suis maintenant certifiée en digital marketing. Aujourd'hui, j’occupe les fonctions de IT Project Manager et consultante freelance. Au sein du Techspace je suis en charge de la gestion des opérations.",
      image: personImg1,
    },
    {
      id: 1,
      title: "ORNELLA SIMBA",
      heading: "Cofondatrice et Présidente",
      description:
        "Au départ j’ai un background juridique. Par la suite, j’ai décidé de m’orienter vers une spécialisation TIC et commence grâce à une formation en Développement Web et Cyber-sécurité. Aujourd'hui je suis certifiée ISO, Information Security Officer et je me suis lancée en tant que freelance consultant pour des grands groupes comme BNP, Proximus, Sopra .",
      image: personImg2,
    },
  ];

  const EquipePersons = [
    {
      id: 0,
      title: "Administrateur | Digital strategist & partnership manager",
      heading: "JEAN YVES KITANTOU",
      description:
        "J’ai un background en sciences politiques. Après une carrière de 8 ans dans ce domaine, suite à un master en business management, je décide de m’orienter vers les métiers de la tech. Aujourd’hui je suis consultant freelance en stratégie digitale et gestion de projet. Au sein du Techspace, je suis en charge de la stratégie digitale et des partenariats avec le public et le privé.",
      image: Eimg1,
    },

    {
      id: "1",
      title: "Trésorier",
      heading: " STEPHANE BODART",
      description:
        "Je suis expert-comptable et trésorier de l'asbl. Au sein du Techspace je gère les aspects financiers et comptables du Techspace.",
      image: Eimg2,
    },
    {
      id: "2",
      title: "PR Manager",
      heading: " ABEL MAGOLU ",
      description:
        " Je suis actif dans le secteur du Digital Marketing en tant que Community Manager et PR. Au sein du Techspace, je suis en charge de la partie partenariat pour les actions de sensibilisation et la tenue des ateliers numériques. ",
      image: Eimg3,
    },

    {
      id: "3",
      title: "Social media manager ",
      heading: " LARA AZIZ ",
      description:
        "Formée en sciences politiques, je suis une passionnée de communication digitale. Raison pour laquelle je suis devenue freelance indépendante en communication digitale via ma société LA consulting. Pour Le Techspace, je suis en charge de la communication social média. ",
      image: Eimg4,
    },

    {
      id: "4",
      title: "ISP program manager",
      heading: "MOHAMMED TAHRI ",
      description: `Je suis un professionnel de l'insertion socioprofessionnelle avec une expérience de plus de 12 ans dans le recrutement et le coaching. Passionné par le développement du "capital humain", je suis responsable des programmes d'insertion professionnelle du DCC.`,
      image: Eimg5,
    },

    {
      id: "5",
      title: "Web designer",
      heading: " GIOVANNA GERBINO ",
      description:
        " Après une pause carrière, j’ai décidé de me tourner vers le webdesign qui a toujours été une passion pour moi. Pour Le Techspace, j’ai la charge de la création des outils de webdesign et graphique.",
      image: Eimg6,
    },
    {},

    {
      id: "6",
      title: "ISP program manager",
      heading: " SAID SENHAJI ",
      description: `Je un professionnel de l'insertion socio-professionnelle avec une expérience de plus de 5 ans au sein de l'institution publique d'Actiris. Durant ces 5 années, j'ai été en charge de la sélection des chercheurs d'emploi en vue d'un projet de formation dans les métiers industriels. J'ai mis en place un accompagnement individuel et collectif pour assurer la transition vers l'emploi des stagiaires en fin de formation, en les mettant en contact avec les entreprises intéressés par les profils qualifiés du centre de référence " Iris TECH".`,
      image: Eimg7,
    },
  ];

  return (
    <Layout>
      <div className="container mx-auto">
        {" "}
        <div className=" max-w-4xl mx-auto">
          <AboutHeader />
          <History />

          <div className=" justify-center items-center flex flex-col lg:flex-row ">
            {person1.map((person) => (
              <AboutPerson item={person} />
            ))}
          </div>
          <Equipe />
        </div>
      </div>
      <div className="mx-0 2xl:mx-72 xl:mx-52 my-10 ">
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
          {EquipePersons.map((person, index) => (
            <Grid item xs={12} md={12} xlg={4} xl={6} xxl={4}>
              {Boolean(Object.keys(person).length) && (
                <EquipePerson item={person} />
              )}
            </Grid>
          ))}
        </Grid>
      </div>
      <Aboutlast />
    </Layout>
  );
};
