import { toast } from "react-toastify";

export const truncate = (str, len) => {
  if (!str) return '';
  if (str.length <= len) return str;
  return str.substring(0, len) + "...";
};

export const updateLoadingToast = (message, type, toastId) => {
  toast.update(
    toastId,
    { render: message, type, isLoading: false, autoClose: 5000, draggable: true, closeOnClick: true }
  )
};