import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";

export const Tybutton = () => {
  const theme = useTheme();

  return (
    <div className=" TyBg flex flex-col justify-center items-center my-10 ">
      <Typography
        className=" leading-10 pt-20 text-center "
        style={{ color: theme.palette.primary.main, fontSize: 25 }}
      >
        <b>
          Tech4Youth dans votre école ? <br /> Parlons-en !
        </b>
      </Typography>
      <Typography
        style={{ fontSize: 10 }}
        className=" text-center mx-7 my-10 leading-6"
      >
        Nous proposons des cours, des stages, des ateliers et des événements
        spéciaux <br /> couvrant un large éventail de sujets liés à la culture
        numérique.
      </Typography>
      <div className="pb-5">
        <Button
          className=" w-52 h-10 text-white mt-3 m-50  aboutButton  "
          style={{
            borderRadius: 60,
            background: "#0C3C60",
            fontSize: 12,
            textTransform: "none",
          }}
        >
          Nous contacter
        </Button>
      </div>
    </div>
  );
};
