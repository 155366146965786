import React, { useEffect, useState } from "react";
import {
  ABOUT,
  ACTIONS,
  BUSTOUR,
  CONTACT,
  DIGITALCAREER,
  TECH4DIVERSITY,
  TECH4YOUTH,
  WELCOME,
  WOMENFORWARD,
} from "../routes/frontend-routes";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import EndImg from "../assets/images/EndImg.webp";
import { truncate } from "../utils/helpers";

export const Aboutlast = () => {
  const theme = useTheme();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="aboutBackgroundImg flex justify-center  py-20 h-150 items-center">
      <div className=" bg-white h-64  flex flex-col items-center justify-center about-white-card ">
        <Typography className="text-center pt-9 px-10 w-full leading-6 ">
          <b>
            Nous travaillons aussi avec un réseau de formateurs expérimentés
            dans différents domaines de la tech (Coding, cybersécurité, digital
            marketing, e-commerce, webdesign, gestion de projets).
          </b>
        </Typography>
        <Button
          className="px-9 py-3 text-white mt-3 font-bold aboutButton"
          style={{ borderRadius: 60, background: "#3179AF", fontSize: 6 }}
        >
          {truncate(
            "Besoin d’un formateur ? N’hésitez pas à nous contacter",
            windowWidth > 768 ? 100 : 25
          )}
        </Button>
      </div>
    </div>
  );
};
