import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Button, Typography, dividerClasses } from "@mui/material";
import MySessionsImg from "../assets/images/MySessionsImg.jpg";

export const Sessiongrid = ({ item }) => {
  const theme = useTheme();

  return (
    <div className="mx:0 w-64 mx-auto">
      <div className=" bg-white  rounded-3xl  my-5 px-5 shadow-lg h-72 ">
        <Typography
          className="mt-5  pt-5"
          style={{
            fontSize: 40,
            fontWeight: "bold",
            color: theme.palette.text.aboutBlue,
          }}
        >
          {item.number}
        </Typography>
        <div
          className="flex flex-row mt-4"
          style={{ fontWeight: "bold", color: theme.palette.text.aboutBlue }}
        >
          <Typography>{item.monYear}</Typography>

          <img src={item.image} alt="clockImg" className="ml-14" />
          <Typography>{item.time}</Typography>
        </div>

        <Typography className="mt-10 text-sm ">{item.noSessions}</Typography>
        <Typography className="pt-5 text-xs">{item.mentor}</Typography>
        <div>
          <Button
            className=" w-28 h-10 mt-6 text-white  aboutButton  "
            style={{
              borderRadius: 60,
              background: "#0C3C60",
              fontSize: 12,
              textTransform: "none",
            }}
          >
            {item.buttons}
          </Button>
        </div>
      </div>
    </div>
  );
};
