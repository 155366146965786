import React, { useEffect, useState } from "react";
import { AuthLayout } from "../AuthLayout";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TechspaceTextField from "../../components/TechspaceTextField";
import TechspaceCheckbox from "../../components/TechspaceCheckbox";
import TechspaceButton from "../../components/TechspaceButton";
import { useDispatch, useSelector } from "react-redux";
import { resendOtpCode, resetData, setAbout, setEmail, setName, setOtpCode, verifyOtpCode } from "./authSlice";
import { toast } from "react-toastify";
import validator from "validator";
import OTPInput from "../../components/auth/OTPInput";
import { REGISTRATION, REGISTRATION_SET_PASSWORD } from "../../routes/frontend-routes";

export const OTPVerify = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { email, otpCode } = useSelector(state => state.auth);

  const onSubmit = () => {
    if(!otpCode || otpCode?.length < 4)
      return toast.error('OTP code not complete');

    dispatch(verifyOtpCode({
      data: { email, otp: otpCode },
      onSuccess: () => navigate(REGISTRATION_SET_PASSWORD)
    }));
  }

  const onResend = () => {
    dispatch(resendOtpCode({ email }));
  }

  useEffect(() => {
    if(!localStorage.registrationData)
      return navigate(REGISTRATION);

    let data = JSON.parse(localStorage.registrationData);
    dispatch(setName(data.name));
    dispatch(setEmail(data.email));
    dispatch(setAbout(data.about));
  }, [])

  return (
    <AuthLayout>
      <div className="flex justify-center">
        <div className="bg-white auth-container p-8 md:p-16 mt-16 w-3/4 sm:w-1/2 lg:w-1/3 overflow-x-auto">
          <Typography variant="h3" className="font-bold mb-8" color='#4D115C'>
            Vérification de l'E-mail
          </Typography>

          <Typography variant="h6" className="font-bold mb-1" color='secondary.main'>
            Code OTP
          </Typography>
          <Typography variant="caption" color='text.caption'>
            Veuillez entrer le code que nous avons envoyé à
          </Typography>
          <br />
          <Typography variant="caption">
            {email}
          </Typography>

          <OTPInput
            className='mb-6'
            onResend={onResend}
            onChange={code => dispatch(setOtpCode(code))}
          />

          <TechspaceButton
            variant="contained"
            className="w-full"
            onClick={onSubmit}
          >
            <Typography variant="h6" className="font-bold normal-case">
              Suivante
            </Typography>
          </TechspaceButton>
        </div>
      </div>
    </AuthLayout>
  );
};
