import React, { useState } from "react";
import {
  ABOUT,
  ACTIONS,
  BUSTOUR,
  CONTACT,
  DIGITALCAREER,
  TECH4DIVERSITY,
  TECH4YOUTH,
  WELCOME,
  WOMENFORWARD,
} from "../routes/frontend-routes";
import { Link } from "react-router-dom";
import logo from "../assets/images/LeTechspace-LOGO.png";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const AboutPerson = ({ item }) => {
  const theme = useTheme();

  return (
    <div className="  relative pb-10 pt-20   ">
      <img
        src={item.image}
        alt="aboutImg1"
        className=" justify-center roundImg  "
      />
      <div
        style={{
          background: theme.palette.primary.main,
          width: "420px",
          height: " 450px",
        }}
        className=" text-white  shadow-lg shadow-black rounded-3xl mx:0  md:mx-10 mt-36 mb-10 pl-5 pr-5  pb-14 "
      >
        <Typography className="pt-32  text-center text-sm ">
          {item.title}
        </Typography>
        <Typography className="pt-5 text-xs">{item.heading}</Typography>
        <Typography
          className=" pb-7 pt-5 w-full leading-6 font-futura-light "
          style={{ fontSize: "11px" }}
        >
          {item.description}
        </Typography>
      </div>
    </div>
  );
};
