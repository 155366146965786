import React from "react";
import { Layout } from "./Layout";
import { Tyheader } from "../components/Tyheader";
import { Tycenter } from "../components/Tycenter";
import { Tybutton } from "../components/Tybutton";
import { TyFooter } from "../components/TyFooter";

export const TechYouth = () => {
  return (
    <Layout>
      <div className="container mx-auto ">
        <Tyheader />
        <Tycenter />
      </div>
      <Tybutton />
      <TyFooter />
    </Layout>
  );
};
