import { Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import tiktok from "../assets/images/tiktok.png";
import facebook from "../assets/images/facebook.png";
import insta from "../assets/images/insta.png";
import linkedin from "../assets/images/linkedin.png";
import {
  ABOUT,
  ACTIONS,
  CONTACT,
  DIGITALCAREER,
  WELCOME,
  WOMENFORWARD,
} from "../routes/frontend-routes";
import { Link } from "react-router-dom";

export const Footer = () => {
  const theme = useTheme();
  const [selected, setSelected] = useState(0);

  const social = [
    {
      id: 0,
      name: "Linkedin",
      url: "https://www.linkedin.com/company/letechspace/",
      image: linkedin,
    },
    {
      id: 1,
      name: "Facebook",
      url: "https://www.facebook.com/letechspace",
      image: facebook,
    },
    {
      id: 2,
      name: "Instagram",
      url: "https://www.instagram.com/letechspace/",
      image: insta,
    },
    {
      id: 3,
      name: "Tiktok",
      url: "https://www.tiktok.com/@letechspace",
      image: tiktok,
    },
  ];

  const links = [
    {
      id: 0,
      name: "Accueil",
      url: WELCOME,
    },
    {
      id: 1,
      name: "Nos actions",
      url: ACTIONS,
    },
    {
      id: 2,
      name: "Digital Career Center",
      url: DIGITALCAREER,
    },
    {
      id: 3,
      name: "Femme Forward",
      url: WOMENFORWARD,
    },
    {
      id: 4,
      name: "À propos",
      url: ABOUT,
    },
    {
      id: 5,
      name: "Contact",
      url: CONTACT,
    },
  ];

  return (
    <section
      style={{ background: theme.palette.footer.main }}
      className="w-full flex flex-col items-center justify-between md:flex-row p-10 pt-16 pb-20"
    >
      <div className="text-white flex flex-col items-center justify-center w-full md:w-1/3">
        <Typography className=" text-base">LE TECHSPACE</Typography>
        <Typography className=" font-bold text-xs font-futura-light mt-4">
          BRUXELLES
        </Typography>
        <Typography className="font-futura-light mt-8 text-center">
          Cantersteen 12, 1000 Bruxelles
        </Typography>
        <Typography className="font-futura-light mt-3 text-center">
          E-mail : info@letechspace.com
        </Typography>
        <Typography className="font-bold text-xs  font-futura-light mt-10">
          WALLONIE
        </Typography>
        <Typography className="font-futura-light mt-4 text-center">
          Esplanade Simone Veil 1, 4000 Liège
        </Typography>
        <Typography className="font-futura-light mt-3 text-center">
          E-mail : info@letechspace.com
        </Typography>
      </div>
      <div className="w-full md:w-1/3 mt-10 md:mt-0 flex flex-col items-center justify-center">
        <Button
          className="px-9 py-3 bg-blue-100 font-bold"
          style={{ borderRadius: 60, color: "#689ADE", fontSize: "13px" }}
        >
          NOUS CONTACTER
        </Button>
        <ul className="flex flex-row mt-12">
          {social.map((icon) => (
            <li key={icon.id} className="mr-2">
              <a href={icon.url} target="_blank" rel="noreferrer">
                <img src={icon.image} className="w-6" alt={icon.name} />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="hidden w-full md:w-1/3 md:flex flex-col items-center justify-center">
        <ul>
          {links.map((link, index) => (
            <li
              key={link.id}
              className="mt-1"
              onClick={() => setSelected(index)}
            >
              <Link
                to={link.url}
                style={{
                  color: selected === index ? "#689ADE" : "white",
                  fontSize: "10px",
                }}
                className="font-Avenir font-light"
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
